import React from "react";

import phoneAppImg from "../../images/demonstrativo_app.png";
import storeIconsImg from "../../images/store_icons.png";

import { Container } from "./styles";

const SectionApp: React.FC = () => {
  return (
    <Container id="manifesto">
      <div className="content">
        <div className="text">
          <p>
            Cozinhar para alguém é o ato mais singelo de transmitir amor. Comida
            é tradição, cultura, proximidade, cuidado… Um aconchego emocional
            que cicatriza qualquer ferida.
          </p>
          <p>
            Receitas de família são tesouros escondidos, heranças, que podem
            acabar naufragando e se perdendo com o tempo. Como salvá-las? Como
            tornar a tecnologia uma aliada aos costumes milenares transmitidos
            de geração em geração?
          </p>
          <p className="bold">
            Use, abuse, compartilhe, aprenda, colecione, comercialize e organize
            suas receitas. Este é o Gastrobook, o seu caderno de receitas
            digital.
          </p>
        </div>
        <div className="img">
          <img className="phone" src={phoneAppImg} />
          <img className="icons" src={storeIconsImg} />
        </div>
      </div>
    </Container>
  );
};

export default SectionApp;
