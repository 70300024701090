import styled from "styled-components";

import bgSectionApp from "../../images/bg_section_app.png";

export const Container = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;

  background-image: linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)),
    url(${bgSectionApp});
  background-size: cover;
  padding: 30px;
  gap: 30px;

  h3 {
    font-weight: 900;
    font-size: 36px;
    color: #fe8348;
  }

  .content {
    display: flex;
    align-items: center;
    gap: 120px;

    .recipe {
      display: flex;
      flex-direction: column;
      gap: 20px;
      flex: 1;

      img {
        width: 100%;
      }

      p {
        line-height: 1.5em;
      }

      p.title {
        font-weight: bold;
        color: #fe8348;
      }
    }
  }
`;
