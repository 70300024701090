import styled from "styled-components";

import bgSectionApp from "../../images/bg_section_app.png";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  background-image: url(${bgSectionApp});
  background-size: cover;
  padding: 30px;
  gap: 30px;

  h3 {
    font-weight: 900;
    font-size: 36px;
    color: #fe8348;
  }

  .content {
    display: flex;
    align-items: center;
    gap: 60px;

    p {
      font-size: 20px;
      margin-bottom: 20px;
    }

    b {
      font-weight: bold;
    }

    p.title {
      font-weight: bold;
      color: #fe8348;
    }

    .grid {
      width: 100%;
      display: grid;
      grid-template-columns: 5fr 5fr 1fr 5fr 5fr;
      grid-template-rows: auto;
      gap: 5px;

      grid-template-areas:
        "a item1 item1 item1 item2"
        "item3 item4 h i item2"
        "item3 item4 n item5 item5"
        "item6 item6 s item5 item5"
        "item6 item6 y z z";

      @media (max-width: 1024px) {
        grid-template-columns: 1fr;
        grid-template-areas:
          "item1"
          "item2"
          "item3"
          "item4"
          "item5"
          "item6";
      }
      .grid-item {
        max-width: 100%;
      }

      .grid-item-1 {
        grid-area: item1;

        text-align: center;
      }

      .grid-item-2 {
        grid-area: item2;
      }

      .grid-item-3 {
        grid-area: item3;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .grid-item-4 {
        grid-area: item4;
      }

      .grid-item-5 {
        grid-area: item5;

        button {
          float: right;
        }
      }

      .grid-item-6 {
        grid-area: item6;

        width: 60%;
        margin: 0 auto;
      }
    }
  }
`;
