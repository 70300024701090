import styled from "styled-components";
import bgHeader from "../../images/bg_header.png";
export const Container = styled.header`
  max-width: 100%;

  .bg {
    height: 90vh;
    background-image: url(${bgHeader});
    background-size: cover;
    background-position: center center;
    overflow: hidden;
  }

  img {
    max-width: 100%;
  }
`;

export const MenuContent = styled.div`
  display: flex;
  justify-content: center;
  position: sticky;
  z-index: 9999;
  top: 0;
  background: #fff;

  .content {
    width: 100%;
  }
`;
