import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;

  padding: 30px;
  gap: 30px;

  h3 {
    font-weight: 900;
    font-size: 36px;
    color: #fe8348;
  }

  .content {
    display: flex;
    align-items: center;
    gap: 120px;

    @media (max-width: 1024px) {
      gap: 0px;
    }

    .recipe {
      display: flex;
      flex-direction: column;
      flex: 1;

      img {
        width: 100%;
      }

      p {
        line-height: 1.5em;
      }
    }
  }
`;
