import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import { Container } from "./styles";

const Navbar: React.FC = () => {
  return (
    <Container className="navbar navbar-expand-lg">
      <div className="container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="bi bi-list"></i>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav">
            <li className="nav-item">
              <AnchorLink
                stripHash
                to="/#home"
                className="nav-link active"
                aria-current="page"
              >
                <i className="bi bi-house-door"></i>
              </AnchorLink>
            </li>
            <li className="nav-item">
              <AnchorLink stripHash to="/#manifesto" className="nav-link">
                Manifesto
              </AnchorLink>
            </li>
            <li className="nav-item">
              <AnchorLink stripHash to="/#nossa-historia" className="nav-link">
                Nossa História
              </AnchorLink>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="navbarDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                O Gastrobook
              </a>
              <ul
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <li>
                  <AnchorLink
                    stripHash
                    to="/#livro-receitas"
                    className="dropdown-item"
                  >
                    Livro de receitas
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink
                    stripHash
                    to="/#criando-receitas"
                    className="dropdown-item"
                  >
                    Criando suas receitas
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink
                    stripHash
                    to="/#educacao-culinaria"
                    className="dropdown-item"
                  >
                    Educação culinária
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink
                    stripHash
                    to="/#muito-alem-aplicativo"
                    className="dropdown-item"
                  >
                    Muito além de um aplicativo
                  </AnchorLink>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <AnchorLink stripHash to="/#contato" className="nav-link">
                Contato
              </AnchorLink>
            </li>
          </ul>
        </div>
      </div>
    </Container>
  );
};

export default Navbar;
