import * as React from "react";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Header from "../components/Header";
import SectionApp from "../components/SectionApp";
import SectionContact from "../components/SectionContact";
import SectionEducation from "../components/SectionEducation";
import SectionGrid from "../components/SectionGrid";
import SectionPromo from "../components/SectionPromo";
import SectionRecipes from "../components/SectionRecipes";
import SectionFeatures from "../components/SectionFeatures";

import "../scss/global.scss";

// markup
const IndexPage = () => {
  return (
    <>
      <main>
        <Header />
        <SectionApp />
        <SectionRecipes />
        <SectionGrid />
        <SectionPromo />
        <SectionEducation />
        <SectionFeatures />
        <SectionContact />
      </main>
      <ToastContainer />
    </>
  );
};

export default IndexPage;
