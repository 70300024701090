import React, { useState } from "react";

import axios from "axios";
import { toast } from "react-toastify";

import { Container } from "./styles";

const SectionContact: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    setLoading(true);

    const { target } = e;

    axios({
      method: "post",
      url: `${process.env.GATSBY_API_URL}/site/message/new`,
      data: new FormData(target),
    })
      .then((r) => {
        toast.success("Mensagem enviada com sucesso!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        (document?.getElementById("contact-form") as HTMLFormElement).reset();

        setLoading(false);
        // handleServerResponse(true, "Thanks!", form);
      })
      .catch((r) => {
        toast.error("Houve um problema ao enviar sua mensagem", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setLoading(false);
        // handleServerResponse(false, r.response.data.error, form);
      });
  };
  return (
    <Container id="contato">
      <h3>Fale conosco</h3>
      <div className="content">
        <form onSubmit={handleSubmit} id="contact-form">
          <label>
            Nome
            <input type="text" name="name" id="name" required />
          </label>
          <label>
            E-mail
            <input type="email" name="email" id="email" required />
          </label>
          <label>
            Assunto
            <input type="text" name="subject" id="subject" required />
          </label>
          <label>
            Mensagem
            <textarea name="message" id="message" rows={5} required />
          </label>
          <button type="submit" disabled={loading}>
            {loading ? "Enviando..." : "Enviar"}
          </button>
        </form>
      </div>
    </Container>
  );
};

export default SectionContact;
