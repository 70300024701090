import React from "react";

import { Container } from "./styles";

import imgRecipe01 from "../../images/img_4.png";
import imgGarfoFaca from "../../images/garfo_faca.png";
import imgChef from "../../images/img_5.png";

const SectionEducation: React.FC = () => {
  return (
    <Container id="educacao-culinaria">
      <div className="content">
        <div className="recipe">
          <img src={imgRecipe01} />
          <p className="title">Educação culinária</p>
          <p>
            Dentro do app você pode comprar e/ou vender cursos sobre criação de
            receitas, técnicas na cozinha, corte, harmonização, e qualquer
            assunto relacionado à gastronomia.
            <br />
            <br />
            Os cursos podem ser gratuitos ou pagos, e ficam salvos dentro do seu
            perfil. É uma maneira prática e rápida de conseguir renda extra,
            construir uma comunidade ou aumentar o seu conhecimento culinário.
          </p>
          <button>Baixar o app grátis</button>
        </div>
        {/* <div className="recipe">
          <img src={imgGarfoFaca} />
          <p className="title">
            Interaja, opine e avalie as receitas de outras pessoas
          </p>
          <p>
            De chefs conceituados a pessoas comuns: tenha acesso a diversos
            pratos, experiências e segredos culinários que outras pessoas estão
            compartilhando.
            <br />
            <br />
            Role o feed, descubra novas delícias, comente, curta e compartilhe a
            receita no instagram e em suas redes sociais.
            <br />
            <br />A interação vai além: avalie com estrelas que vão de 1 a 5, e
            marque como “Já fiz”, “Já provei” ou “Quero fazer” para que outros
            usuários sejam beneficiados com a sua opinião
          </p>
        </div> */}
        <div className="recipe">
          <img src={imgChef} />
        </div>
      </div>
    </Container>
  );
};

export default SectionEducation;
