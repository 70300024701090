import styled from "styled-components";

import bgSectionApp from "../../images/bg_section_app.png";

export const Container = styled.section`
  display: flex;
  justify-content: center;

  background-image: url(${bgSectionApp});
  background-size: cover;
  padding: 30px;

  .content {
    display: flex;
    align-items: center;
    gap: 30px;
    border-bottom: 1px solid #333;
    padding-bottom: 60px;

    .text {
      display: flex;
      flex-direction: column;
      gap: 30px;

      p {
        font-size: 30px;
        color: #fe8348;
      }
    }

    .img {
      display: flex;
      align-items: flex-end;
      .phone {
        width: 30vw;
      }
      .icons {
        width: 100px;
      }
    }
  }
`;
