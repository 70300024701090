import styled from "styled-components";

import bgSectionApp from "../../images/bg_section_app.png";

export const Container = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;

  background-image: url(${bgSectionApp});
  background-size: cover;
  padding: 30px;
  gap: 30px;
  overflow: hidden;

  * {
    z-index: 1;
  }

  h3 {
    font-weight: 900;
    font-size: 36px;
    color: #fe8348;
  }

  .content {
    display: flex;
    align-items: center;
    gap: 120px;

    .recipe {
      display: flex;
      flex-direction: column;
      gap: 20px;
      flex: 1;
      position: relative;

      img {
        width: 100%;
      }

      p {
        line-height: 1.5em;
      }

      p.title {
        font-weight: bold;
        color: #fe8348;
      }

      &.small {
        flex: 0.8;
        display: block;
        z-index: 2;

        .img1 {
          width: 200px;
        }

        p {
          width: 50%;
          margin: 0 auto;

          button {
            margin-top: 30px;
          }
        }

        .img2 {
          margin-top: 30px;
          width: 200px;
          float: right;
          margin-right: -100px;
        }
      }

      &.orange {
        p {
          color: #333;
        }
        p.title {
          color: #fff;
        }

        .circle {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 150%;

          z-index: 0;
        }
      }

      @media (max-width: 1024px) {
        &.small {
          display: flex;
          align-items: center;
          flex: 1;

          p {
            width: 100%;
            text-align: center;
          }
          .img1,
          .img2 {
            margin-top: 0;
            width: 200px;
            float: none;
            margin-right: 0;
          }
        }
      }
    }
  }
`;
