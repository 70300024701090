import React from "react";

import { Container } from "./styles";

import imgFirstRecipe from "../../images/receita_01.png";
import imgSecondRecipe from "../../images/receita_02.png";

const SectionRecipes: React.FC = () => {
  return (
    <Container id="nossa-historia">
      <h3>Nossa História</h3>
      <div className="content">
        <div className="recipe">
          <img src={imgFirstRecipe} />
          <p>
            A culinária sempre foi um ponto de encontro em nossa família. Entre
            nós, existe uma tradição que perdura há anos: presentear cada jovem
            familiar com um caderno de receitas feito à mão, que guarda todos os
            segredos gastronômicos dos nossos antepassados.
            <br />
            <br />
            Receitas de vó e receitas de família; receitas simples, ou
            surpreendentes; receitas regionais e tradicionais.
            <br />
            <br />
            Ele é personalizado e existe toda uma mágica ali: a caligrafia, o
            jeito de escrever, a maturidade sendo construída ano após ano,
            receita após receita.
          </p>
        </div>
        <div className="recipe">
          <p>
            E, depois de tantas páginas rasgarem e tantas relíquias se perderem,
            nasceu a ideia: como podemos colecionar, organizar e compartilhar as
            nossas receitas com outras pessoas em um repositório digital?
            <br />
            <br />
            Mais que isso: como criar uma confraria e um senso de comunidade
            ligado à essência gastronômica de pessoas comuns, buscando receitas
            comuns — ou extraordinárias?
          </p>
          <img src={imgSecondRecipe} />
        </div>
      </div>
      <div className="content">
        <button>Conheça o Gastrbook</button>
      </div>
    </Container>
  );
};

export default SectionRecipes;
