import React from "react";

import gridImg01 from "../../images/img_01.png";
import gridImg02 from "../../images/img_02.png";
import gridImg03 from "../../images/img_03.png";

import { Container } from "./styles";

const SectionGrid: React.FC = () => {
  return (
    <Container id="livro-receitas">
      <div className="content">
        <div className="grid">
          <h3 className="grid-item grid-item-1">O Gastrobook</h3>
          <img src={gridImg02} className="grid-item grid-item-2" />
          <article className="grid-item grid-item-3">
            <p>
              Quer entender por que o nosso caderno de receitas digital é
              diferente de qualquer outro que existe no mercado culinário?
            </p>
            <p>
              Porque é uma rede social personalizável que conversa com suas
              necessidades.
            </p>
            <p>
              Sinta o gostinho de uma experiência que atravessa gerações e vai
              te surpreender.
            </p>
          </article>
          <img src={gridImg01} className="grid-item grid-item-4" />

          <article className="grid-item grid-item-5">
            <p className="title">Livro de receitas</p>
            <p>
              Sabe todas aquelas receitas de família espalhadas pela gaveta de
              casa? Ou todos aqueles prints de comidas deliciosas perdidos na
              galeria do celular? É hora de organizá-los.
            </p>
            <p>
              Crie seu próprio caderno e adicione suas próprias receitas, ou
              salve as de outras pessoas para conhecer novos sabores.
            </p>
            <p>
              Cada caderno tem seu próprio nome e categoria. Assim, um caderno
              de bolos, por exemplo, poderia ser:
            </p>
            <p>
              <b>Receitas de bolos</b>
              <br />
              Doces, Festa, Confeitaria.
            </p>
            <p>
              Você também pode categorizar como: cozinha do dia a dia, receita
              por ingredientes, pratos temáticos, cozinha regional, cozinha
              sazonal, e muito mais. As possibilidades são infinitas!
            </p>

            <button>Baixar o Gastrobook</button>
          </article>

          <img src={gridImg03} className="grid-item grid-item-6" />
        </div>
      </div>
    </Container>
  );
};

export default SectionGrid;
